import CoverdashQuotes from '@coverdash/quotes';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useGetCoverdashApplicationIdQuery } from '../../app/services/coverdash';
import './CoverdashLanding.scss';

const CoverdashLanding = (): JSX.Element => {
  const coverdashLicence = process.env.COVERDASH_LICENCE;
  const coverdashEnv = process.env.COVERDASH_ENV;
  const [searchParams] = useSearchParams();
  const qFormGuid = searchParams.get('formId') || '';
  const snapChannel = searchParams.get('snapChannel') || '';
  const { data, isError } = useGetCoverdashApplicationIdQuery({
    qFormGuid: qFormGuid,
    snapChannel: snapChannel,
  });
  useEffect(() => {
    if (isError) {
      window.location.href = `${process.env.COVERDASH_URL}`;
    }
  }, [isError]);
  return (
    <>
      <Helmet>
        <title>Coverdash - Business.LendingTree.com</title>
      </Helmet>
      <div className="coverdash">
        <CoverdashQuotes
          applicationSubmissionId={data?.applicationId}
          autoResize={false}
          env={coverdashEnv}
          hideScrollbar={true}
          hybrid={true}
          license={coverdashLicence}
          styles={{ minHeight: '100vh' }}
        />
        <div className="app__container coverdash__disclosure">
          <p>
            Insurance is offered through Coverdash Inc., a licensed insurance producer. Any
            insurance-related information is provided by Coverdash Inc. for general informational
            purposes only and does not in any way alter or amend the terms, conditions, or
            exclusions of any insurance policy. Insurance coverage may not be available in all
            jurisdictions or to all customers. Coverdash Inc. is a licensed insurance producer with
            its main office located at 286 5th Ave 5th Floor, New York, NY 10001. For licensing
            information, visit&nbsp;
            <a href="https://www.coverdash.com/licenses">
              Coverdash | Business Insurance Online Instantly
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
};

export default CoverdashLanding;
