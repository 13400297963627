import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { MEDIA_QUERIES } from '../../constants';
import { useAuth } from '../../features/Auth';
import { useLTAnalytics } from '../../hooks';
import { Button } from '../Button';
import { FlexColumn, FlexRow } from '../Flex';
import { LtIcon } from '../Icon';
import BackArrowImage from './images/back-arrow.png';
import LtCoverdashLogoImage from './images/lt-coverdash-logo.svg';
import LtLogoImage from './images/lt-logo.svg';
import './PublicNavbar.scss';

interface PublicNavbarProps {
  showBackArrow?: boolean;
  showSignIn?: boolean;
}

const PublicNavbar = ({
  showBackArrow = false,
  showSignIn = false,
}: PublicNavbarProps): JSX.Element => {
  const isXsDown = useMediaQuery({ query: MEDIA_QUERIES.xsDown });
  const analyticsSignIn = useLTAnalytics('Sign in');
  const location = useLocation();
  const IsLtCoverdashLogo = location.pathname.includes('coverdash');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = () => {
    login();
  };

  return (
    <nav className="public-navbar">
      <FlexRow className="public-navbar__row">
        <FlexColumn className="public-navbar__column public-navbar__column--back" columns={4}>
          {showBackArrow && (
            <>
              {!isXsDown && (
                <a className="public-navbar__back" href="#" onClick={() => navigate(-1)}>
                  <LtIcon name={'ChevronLeft'} />
                  <span className="public-navbar__back-text">Go Back</span>
                </a>
              )}
              {isXsDown && (
                <a className="public-navbar__back" href="#" onClick={() => navigate(-1)}>
                  <img alt="Back-Arrow" src={BackArrowImage} />
                </a>
              )}
            </>
          )}
        </FlexColumn>
        <FlexColumn className="public-navbar__column" columns={4}>
          {IsLtCoverdashLogo ? (
            <div className="public-navbar__coverdashlogo">
              <img alt="LT-Logo" className="lt-coverdash-logo" src={LtCoverdashLogoImage} />
            </div>
          ) : (
            <div className="public-navbar__logo">
              <img alt="LT-Logo" className="lt-logo" src={LtLogoImage} />
            </div>
          )}
        </FlexColumn>
        <FlexColumn className="public-navbar__column public-navbar__column--login" columns={4}>
          {showSignIn && (
            <Button compact={true} {...analyticsSignIn} onClick={handleLogin}>
              Sign In
            </Button>
          )}
        </FlexColumn>
      </FlexRow>
    </nav>
  );
};

export default PublicNavbar;
