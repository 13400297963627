import CoverdashCreateBusinessRequest from '../../features/CoverDash/types/CoverdashCreateBusinessRequest';
import CoverdashCreateBusinessResponse from '../../features/CoverDash/types/CoverdashCreateBusinessResponse';
import { borrowerPortalApi } from './borrowerPortalBase';

export const coverdashApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getCoverdashApplicationId: builder.query<
      CoverdashCreateBusinessResponse,
      CoverdashCreateBusinessRequest
    >({
      query: (request) =>
        `api/Coverdash/getApplication?formId=${request.qFormGuid}&snapChannel=${request.snapChannel}`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetCoverdashApplicationIdQuery } = coverdashApi;
